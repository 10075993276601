* {
  margin: 0;
  box-sizing: border-box;
  transition: 0s ease-in-out;
  font-family: "Work Sans", sans-serif;
  text-decoration: none;
  input {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
  }
}

html {
  width: 100vw;
  background-color: #ffffff;
  scroll-behavior: smooth;
}

.App {
  margin: 0;
  height: auto;
  width: auto;
  background-color: rgb(255, 255, 255);
  .OfflineApp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 4px;
    border-left: 4px solid #e80000;
    background-color: #fff1f1;
    position: fixed;
    top: 155px;
    left: 5%;
    width: calc(100% - 10%);
    z-index: 10;
    h1 {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      img {
        width: 20px;
        margin-right: 10px;
      }
      span {
        font-weight: 600;
        margin-right: 10px;
      }
    }
    .OfflineAppClose {
      width: 17px;
      cursor: pointer;
    }
  }
  .callButtonMainApp{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 10px;
    bottom: 10px;
    background-color: #e80000;
    padding: 15px 20px;
    border-radius: 50px;
    z-index: 5;
    color: white;
    font-weight: 600;
    img{
      width: 20px;
      filter: invert(1);
      margin-right: 10px;
    }
  }
}
@media (max-width: 790px) {
  .App {
    // padding: 3320px;
    .OfflineApp {
      width: calc(100% - 10%);
      top: 115px;
      left: 5%;
    }
  }
}
@media (min-width: 1400px) {
  .App {
    .OfflineApp {
      width: calc(100% - 40%);
      left: 20%;
    }
  }
}
